<template>
  <div>
    <DataTable
      v-model:filters="rowFilters"
      v-model:expandedRows="expandedRows"
      :value="items"
      :row-hover="true"
      :loading="loading"
      :paginator="true"
      :rows="10"
      :scrollable="true"
      :lazy="true"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      filterDisplay="row"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      class="p-datatable-sm"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <template v-if="hasHeader" #header>
        <slot name="header"></slot>
      </template>
      <slot name="columns"></slot>
      <template #expansion="details">
        <div>
          <DataTable
            :value="details.data.details"
            :scrollable="true"
            class="p-datatable-sm bg-receive"
            showGridlines
          >
            <template #empty>
              <div class="text-center">Tidak ada data.</div>
            </template>
            <template #loading>
              <div class="text-center">Memuat data, harap tunggu...</div>
            </template>
            <Column
              field="pr_no"
              header="No Pembelian"
              class="bg-gray"
              style="min-width: 12rem"
            />
            <Column
              field="item_sku"
              header="SKU"
              style="min-width: 8rem"
              class="bg-gray"
            />
            <Column
              field="item_barcode_1"
              class="bg-gray"
              header="Barcode"
              style="min-width: 8rem"
            />
            <Column
              field="item_name"
              header="Deskripsi"
              class="bg-gray"
              style="min-width: 12rem"
            />
            <Column
              field="unit_b"
              header="Satuan"
              class="bg-gray"
              style="min-width: 6rem"
            >
              <template #body="slotProps">
                {{ slotProps.data.unit_b }}.{{ slotProps.data.unit_s }}
              </template>
            </Column>
            <Column
              field="ubox"
              header="ISI"
              style="
                min-width: 6rem;
                justify-content: flex-end;
                background-color: #f8f9fa;
              "
            />
            <Column
              field="qty_b"
              header="qty (b)"
              style="min-width: 6rem; justify-content: flex-end"
            />
            <Column
              field="qty_s"
              header="qty (s)"
              style="min-width: 6rem; justify-content: flex-end"
            />
            <Column
              field="qty"
              header="QTY (PCS)"
              style="
                min-width: 8rem;
                justify-content: flex-end;
                background-color: #f8f9fa;
              "
            >
            </Column>

            <Column
              field="cost"
              header="Harga"
              style="min-width: 8rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.cost) }}
              </template>
            </Column>
            <Column
              field="cost_per"
              header="PER"
              style="min-width: 5rem; justify-content: flex-start"
            >
            </Column>

            <Column
              field="disc_reg"
              header="DISC.REG"
              style="min-width: 5rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                {{
                  slotProps.data.disc_reg > 100
                    ? formatCurrency(slotProps.data.disc_reg)
                    : `${slotProps.data.disc_reg || 0}%`
                }}
              </template>
            </Column>
            <Column
              field="disc_ext"
              header="DISC.EXT"
              style="min-width: 5rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                {{
                  slotProps.data.disc_ext > 100
                    ? formatCurrency(slotProps.data.disc_ext)
                    : `${slotProps.data.disc_ext || 0}%`
                }}
              </template>
            </Column>
            <Column
              field="tax"
              header="PPN"
              style="min-width: 3rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                <span style="text-align: right">
                  {{ slotProps.data.tax }}%
                </span>
              </template>
            </Column>
            <Column
              field="bruto"
              header="Jumlah"
              class="bg-gray"
              style="
                min-width: 12rem;
                max-width: 12rem;
                justify-content: flex-end;
              "
            >
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.bruto) }}
              </template>
            </Column>
            <template #footer>
              <table class="flex justify-content-end">
                <tr>
                  <td width="100%">
                    <table class="justify-content-end text-700">
                      <tr>
                        <td>SUB TOTAL</td>
                        <td style="width: 150px" class="text-right">
                          {{ formatCurrency(details.data.bruto || 0) }}
                        </td>
                      </tr>
                      <tr>
                        <td>DISC REGULER</td>
                        <td class="text-right">
                          {{ formatCurrency(details.data.disc_reg || 0) }}
                        </td>
                      </tr>
                      <tr>
                        <td>DISC EXTRA</td>
                        <td class="text-right">
                          {{ formatCurrency(details.data.disc_ext || 0) }}
                        </td>
                      </tr>
                      <tr>
                        <td>PPN</td>
                        <td class="text-right">
                          {{ formatCurrency(details.data.taxv) }}
                        </td>
                      </tr>
                      <tr>
                        <td>GRAND TOTAL</td>
                        <td class="text-right">
                          {{ formatCurrency(details.data.total || 0) }}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </template>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { formatCurrency } from '@/helpers/index'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
    filters: {
      type: Object,
      default() {
        return {}
      },
    },
    hasHeader: {
      type: Boolean,
      defaut: false,
    },
  },
  data() {
    return {
      expandedRows: [],
      rowFilters: this.filters,
      options: this.gridOptions,
    }
  },
  methods: {
    initFilters() {
      this.rowFilters = this.$props.filters
      this.onFilter()
    },
    formatCurrency(val) {
      return formatCurrency(val, 0, 0)
    },
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.rowFilters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.filters = this.rowFilters
      this.options.page = 1
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
  },
}
</script>
<style>
.bg-receive .bg-gray {
  background-color: #f8f9fa;
}
</style>
